import axios from 'axios';
import { PUBLISH } from 'urls';

const { useState } = require('react');

const SSEEventsPub = () => {
  const [inputValue, setInputValue] = useState('');

  const onClick = async () => {
    await axios.post(`${PUBLISH}/${inputValue}`);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  return (
    <div>
      <label>Send value to event:</label>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}></input>
      <button onClick={onClick}>Send event</button>
    </div>
  );
};

export default SSEEventsPub;
