const { useEffect, useState } = require('react');
const { SSE_SUB } = require('urls');

const SSEEventsSub = () => {
  const [sseEvents, setSSEEvents] = useState([]);
  useEffect(() => {
    const eventSource = new EventSource(`${SSE_SUB}`);
    eventSource.onmessage = (messageEvent) => {
      setSSEEvents((prevEvents) => [...prevEvents, messageEvent.data]);
    };
    return () => {
      eventSource.close();
    };
  }, []);

  return (
    <div>
      <h1>Events Received:</h1>
      <ul>
        {sseEvents.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    </div>
  );
};

export default SSEEventsSub;
