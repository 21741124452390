import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { SecurityProvider } from 'security';
import { SettingsContext } from './contextAPI/settings';
import axios from 'axios';
import { CSRF_TOKEN_ENDPOINT, SETTINGS } from 'urls';
import { loadJS } from 'utils/helper';
import { ConfigurationProvider } from 'contextAPI/configurationContext';
import { decrypt, generateNonce } from 'security/Hash';
import IdleMonitor from 'pages/ParticipantDataCollection/components/ApplicationSession/TimeoutComponent.react';

function bootStrapApplication() {
  let nonce = generateNonce();
  return axios
    .get(SETTINGS, { params: { nonce: nonce } })
    .then((res) => JSON.parse(decrypt(res.data, nonce)))
    .then((settings) =>
      getCsrfToken().then((csrf) => ({ ...settings, ...csrf })),
    )
    .then((settings) => initilizeApp(settings));
}

async function getCsrfToken() {
  return axios.get(CSRF_TOKEN_ENDPOINT).then((res) => ({ csrf: res.data }));
}

function initilizeApp(settings) {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  //loading cookie script
  loadJS(
    'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    '2c0a40e5-b387-4755-b268-44a200578a02-test',
  );
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <SettingsContext.Provider value={settings}>
          <ConfigurationProvider>
            {/* <SecurityProvider> */}
            <App />
            <IdleMonitor />
            {/* </SecurityProvider> */}
          </ConfigurationProvider>
        </SettingsContext.Provider>
      </BrowserRouter>
    </React.StrictMode>,
  );
}

bootStrapApplication();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
